import React from 'react';
import Obfuscate from 'react-obfuscate';

class About extends React.Component {

    constructor( props ){
        super();
        this.state = {
        }
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        
    }


    render(){
        return<div className={`row h-100`}>
            <div className={`col-md-8 offset-md-2 col-sm-12`}>
                <div className={`header-row`}>
                    <div className={`col-12`}>
                        <h1>About Guitar Tools</h1>
                    </div>
                </div>
                <div className={``}>
                    <div className={`col-12`}>
                        <p>There are millions of guitar players out there of all different skillsets and ambitions. Some of you may be trying to master the guitar, while others are just beginning or have felt stuck in a rut for a while. We're here to just help you get a little better. Our aim is to provide a set of easy to use tools that you can use to supplement your daily practice, guitar lessons, or videos you've been watching on youtube and instagram. There's so much out there and it seems like everyone is better than you. Forget that. Run your own race. Be better than you were yesterday. We're here to help.</p>
                        <p>In the coming months, we'll be launching more guitar chord flash cards, chord charts, scale charts, arpeggio exercises, a blog, and a tool to share your progress with others online and more. If there's any kind of tool that you wish existed, drop us a line at &nbsp;
                            <Obfuscate 
                                email="hello@guitar-tools.com"
                                headers={{
                                    subject: 'Hello Guitar Tool!',
                                    body: 'I had an idea...'
                                }}
                                target="_blank"
                            /></p>          
                    </div>
                </div>
            </div>
        </div>
        
    }
}
export default About