import React from 'react';

class SignUp extends React.Component {

    constructor( props ){
        super();
        this.state = {
        }
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        
    }

    render(){
        const styles = {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '100%'
        }
        return <div className={`row h-100`}>
            <div className={`col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12`}>
                <div className={`header-row text-center`}>
                    <div className={`col-12`}>
                        <h1>Sign Up for Updates</h1>
                    </div>
                </div>
                <iframe title="sign-up-form" width="730" height="640" src="https://e8eef5e2.sibforms.com/serve/MUIEAOT5oSAlIku2jIfqzWvycdD4gIZrENcZRSARhVkF8NQ1mlV-LQWSDoKMVDAMgFCu40ITXveEah8JYNOJiHNjMeTN5wuHjRJ5ijGcOtzYf-MgnJBWoGBPS5Y3DmvRTLdSd-CFfTbk2ctoIbBIYMjMcvVtCsEbIXKa9ULAxkfvAruG9c2oiM0q5zG9J1EEXupIiFgLRyGt9po7" frameBorder="0" scrolling="auto" allowFullScreen style={styles}></iframe>
            </div>
        </div>
    }
}
export default SignUp