import React from 'react';

import './sass/ChordDropdown.scss';

class ChordDropdown extends React.Component {

    constructor(props) {
        super();
        this.state = {
            chordSet: "maj"
        }
    }
/*
    renderChordSetDropdown = (chordSets) => {
        return (
            <select 
                className={`w-auto custom-select ml-auto order-2`}
                onChange={this.onSelectChordSet.bind(this)}
                ref={ this.selectorEl }
                value={ this.state.chordSet }
            >
                <option value='none'>Custom</option>
                {
                    chordSets.map((chordSet) =>{
                        return <option value={chordSet.id} href="#!" key={chordSet.name}>{chordSet.name}</option>
                    })
                }
            </select>
        )
    }
*/
    render(){
        const chordSetOptions = [];
        for( const chordSet of this.props.chordSets){
            chordSetOptions.push(<option value={chordSet.id} href="#!" key={chordSet.name}>{chordSet.name}</option>)
        }
                
        return (
            // <div>
            //     { this.renderChordSetDropdown(this.props.chordSets) }
            // </div>
            <select 
                className={`w-auto custom-select ml-auto order-2`}
                onChange={this.props.onSelectChordSet}
                ref={ this.selectorEl }
                value={ this.props.chordSet }
            >
                {chordSetOptions}
            </select>
        )
    }
}

export default ChordDropdown