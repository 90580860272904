import React from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components'
import { createBrowserHistory } from 'history';

import FlashCards from './FlashCards'
import About from './About'
import SignUp from './SignUp'

import './App.scss';
import logo from './images/guitar-tools-logo.svg'
import twitterLogo from './images/Twitter_Logo_WhiteOnImage.png'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const trackingId = "G-VBJ8F2KF7Y"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);
window.gtag('event', 'login', {method: 'Google'});

const history = createBrowserHistory();

const chords = [
  ["A", "Am", "Amaj7", "A7", "Am7", "Asus4", "Asus2"],
  ["A#", "A#m", "A#maj7", "A#7", "A#m7", "A#sus4", "A#sus2"],
  ["B", "Bm", "Bmaj7", "B7", "Bm7", "Bsus4", "Bsus2"],
  ["C", "Cm", "Cmaj7", "C7", "Cm7", "Csus4", "Csus2"],
  ["C#", "C#m", "C#maj7", "C#7", "C#m7", "C#sus4", "C#sus2"],
  ["D", "Dm", "Dmaj7", "D7", "Dm7", "Dsus4", "Dsus2"],
  ["D#", "D#m", "D#maj7", "D#7", "D#m7", "D#sus4", "D#sus2"],
  ["E", "Em", "Emaj7", "E7", "Em7", "Esus4", "Esus2"],
  ["F", "Fm", "Fmaj7", "F7", "Fm7", "Fsus4", "Fsus2"],
  ["F#", "F#m", "F#maj7", "F#7", "F#m7", "F#sus4", "F#sus2"],
  ["G", "Gm", "Gmaj7", "G7", "Gm7", "Gsus4", "Gsus2"],
  ["G#", "G#m", "G#maj7", "G#7", "G#m7", "G#sus4", "G#sus2"]
]

const chordSets = [
  {
    "id": "maj",
    "name": "Major Chords",
    "patterns": [/([a-g]{1}[#]?)$/gi]
  },
  {
    "id": "min",
    "name": "Minor Chords",
    "patterns": [/([a-g]{1}[#]?m)$/gi]
  },
  {
    "id": "maj7",
    "name": "maj7 Chords",
    "patterns": [/([a-g]{1}[#]?maj7)$/gi]
  },
  {
    "id": "7th",
    "name": "7th Chords",
    "patterns": [/([a-g]{1}[#]?7)$/gi]
  },
  {
    "id": "m7",
    "name": "m7 Chords",
    "patterns": [/([a-g]{1}[#]?m7)$/gi]
  },
  {
    "id": "all7th",
    "name": "All 7th Chords",
    "patterns": [/([a-g]{1}[#]?maj7)$/gi, /([a-g]{1}[#]?7)$/gi, /([a-g]{1}[#]?m7)$/gi]
  },
  {
    "id": "sus2",
    "name": "sus2 Chords",
    "patterns": [/([a-g]{1}[#]?sus2)$/gi]
  },
  {
    "id": "sus4",
    "name": "sus4 Chords",
    "patterns": [/([a-g]{1}[#]?sus4)$/gi]
  },
  {
    "id": 'all',
    "name": "All Chords",
    "patterns": [/(.*)/gi]
  }
]

const HeadingLogo = styled.img`
  height: 64px;
  width: 100%;
`;


class App extends React.Component {

  constructor( props ){
    super();
    this.state = {
    }
  }

  componentDidMount() {
    // this.matchChordsToPatterns(chordSets[0].patterns);
  }

  render(){
    return (
      <Router history={history}>
        <div className="App">
          <header className="App-header">
            <Link to="/"><HeadingLogo className="header-logo" src={logo} alt="Guitar Tools"/></Link>
          </header>
          <div className="external-container">
            <div className="container h-100">
            <Switch>
              <Route exact path="/">
                <FlashCards chords={chords} chordSets={chordSets} />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/signup">
                <SignUp />
              </Route>
            </Switch>
            </div>
          </div>
          <footer className="App-footer">Guitar Tools Copyright © 2021&nbsp;|&nbsp;<Link to="/about">About</Link>&nbsp;|&nbsp;<Link to="/signup">Sign Up for Updates</Link>&nbsp;|&nbsp;
            <a href="https://twitter.com/GuitarToolsApp" target="_blank"  rel="noopener noreferrer"><img className='twitter-logo' src={twitterLogo} alt="Guitar Tools on twitter"></img></a>
          </footer>
        </div>
      </Router>
    );
  }
  
}

export default App;
