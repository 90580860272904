import React from 'react';
import styled, {keyframes} from 'styled-components'

import './sass/FlashCard.scss';

const animateOut = keyframes`
    0% { transform: translateX(-50%) translateY(-50%); opacity: 1.0; }
    100% { transform: translateX(-100%) translateY(-50%); opacity: 0.0 }
`

const animateIn = keyframes`
    0% { transform: translateX(0%) translateY(-50%); opacity: 0.0; }
    100% { transform: translateX(-50%) translateY(-50%); opacity: 1.0 }
`

const Card = styled.div`
    height: 350px;
    width: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    background: #FFFFFF;
    border: 1px solid rgba(1, 1, 1, 0.59);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    &.out {
        opacity: 0;
    }

    &.animate-in{
        // animation-delay: 0.2s;
        animation-name: ${animateIn};
        animation-duration: .25s;
        animation-timing-function: ease-out;
    }

    &.animate-out{
        animation-name: ${animateOut};
        animation-duration: .25s;
        animation-timing-function: ease-out;
    }
`;

class FlashCard extends React.Component {

    constructor( props ){
        super();
        this.state = {
            animationClass: ''
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.chord !== this.props.chord && (this.state.animationClass === '' || this.state.animationClass === 'out') ) {
            if(this.props.order === 'new'){
                this.setState({
                    animationClass: 'animate-in'
                });
            } else if(this.props.order === 'old'){
                this.setState({
                    animationClass: 'animate-out'
                });
            }
        }
    }

    onAnimationEnd = () => {
        if(this.props.order === 'new'){
            this.setState({
                animationClass:''
            });
        } else if(this.props.order === 'old'){
            this.setState({
                animationClass:'out'
            });
        }
    };

    render(){
        return (
            <Card 
                className={`flashcard text-center card card-block mx-auto justify-content-center ${this.state.animationClass}`}
                onAnimationEnd={this.onAnimationEnd}
                >
                <h1>
                    {this.props.chord}
                </h1>
            </Card>
        )
    }
}


export default FlashCard