import React from 'react';
import ChordDropdown from './ChordDropdown'
// import ChordMenu from './ChordMenu'
import FlashCard from './FlashCard';
import ReactBootstrapSlider from 'react-bootstrap-slider';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css"
import './sass/FlashCards.scss';

var shuffleArray = (arr) => {
    return arr.map(a => [Math.random(), a]).sort((a, b) => a[0] - b[0]).map(a => a[1]);
}

class RadioButton extends React.Component {
    render() {
        return <div className={`custom-control custom-radio`}>
            <input type="radio" className="custom-control-input" id={this.props.radioId} value={this.props.value} name={this.props.name} checked={this.props.checked} onChange={this.props.onChange}/>
            <label className="custom-control-label" htmlFor={this.props.radioId}>{this.props.label}</label>&nbsp;
        </div>
    }
}

class StartMenu extends React.Component {

    render() {
        const { advanceSpeed } = this.props;
        /*const horizontalLabels = {
            500: 'Fast',
            8000: 'Slow'
        }*/
        const formatSec = value => { 
            return `${value/1000.0} sec`
        };
        const autoAdvanceOn = this.props.autoAdvance === true;

        return <div className={'StartMenu'}>
            <h1>Chord Flashcards</h1>
            <div className="row">
                <div className="col">
                <table className={`w-100`}>
                    <tbody>
                    <tr className={``}>
                        <td><label>Chord Set</label></td>
                        <td><ChordDropdown chordSets={this.props.chordSets} onSelectChordSet={this.props.onSelectChordSet}></ChordDropdown></td>
                    </tr>
                    <tr className={``}>
                        <td>
                            <label>Auto-advance</label>
                        </td>
                        <td>
                            <RadioButton name='advance' radioId='advance-1' value='false' label='Off'
                                checked={this.props.autoAdvance === false} onChange={this.props.onChangeAdvance}></RadioButton>
                            <RadioButton name='advance' radioId='advance-2' value='true' label='On'
                                checked={this.props.autoAdvance === true} onChange={this.props.onChangeAdvance}></RadioButton>
                        </td>
                    </tr>
                    
                    {/* { autoAdvanceOn &&  */}
                        <tr className={``}>
                            <td>
                                <label>Speed</label>
                            </td>
                            <td>
                                <ReactBootstrapSlider
                                    min={500}
                                    max={8000}
                                    step={500}
                                    formatter={formatSec}
                                    value={advanceSpeed}
                                    change={this.props.onSpeedChange}
                                    disabled={autoAdvanceOn ? '' : 'disabled'}
                                />
                            </td>
                        </tr>
                    {/* } */}
                    <tr className={``}>
                        <td>
                            <label>Flashcard Count</label>
                        </td>
                        <td>
                            <RadioButton name='cardMax' radioId='cardMax-1' value='10' label='10'
                                checked={this.props.cardMax === 10} onChange={this.props.onChangeCount}></RadioButton>
                            <RadioButton name='cardMax' radioId='cardMax-2' value='25' label='25'
                                checked={this.props.cardMax === 25} onChange={this.props.onChangeCount}></RadioButton>
                            <RadioButton name='cardMax' radioId='cardMax-3' value='0' label='Loop'
                                checked={this.props.cardMax === 0} onChange={this.props.onChangeCount}></RadioButton>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <button className="btn btn-primary" onClick={this.props.startGame}>Start</button>
                </div>
            </div>
        </div>
    }
}


class CardCanvas extends React.Component {

    render(){
        return <div className={`card-canvas h-100 d-flex flex-column`}>
            <div><h3>{this.props.selectedChordSet.name}</h3></div>
            <div className={`active-card-row`}>
                {/* old card */}
                <FlashCard order='old' chord={this.props.prevChord}></FlashCard>
                {/* new card */}
                <FlashCard order='new' chord={this.props.chord}></FlashCard>
            </div>
            <div className={`text-center`}>
                { this.props.chordMax > 0 && 
                    <div className={`d-block flex-grow-0 mb-2`}>{this.props.cardCount + 1}/{this.props.chordMax}</div>
                }
                <button className="btn btn-primary flex-grow-0" onClick={this.props.endRound}>Reset</button>
            </div>
        </div>
    }
}


class FlashCards extends React.Component {

    constructor( props ){
        super();
        this.state = {
            started: false,
            activeChords: [],
            chordMix: [],
            chordIndex: 0,
            cardCount: 0,
            cardMax: 10,
            selectedChordSet: undefined,
            autoAdvance: false,
            advanceSpeed: 1000,
            advanceIntervalId: undefined
        }
        this.cardBody = React.createRef();
        this.clickNextChord = this.clickNextChord.bind(this);
        this.startGame = this.startGame.bind(this);
        this.endRound = this.endRound.bind(this);
        this.onSelectChordSet = this.onSelectChordSet.bind(this);
        this.onSpeedChange = this.onSpeedChange.bind(this);
        this.onChangeCount = this.onChangeCount.bind(this);
    }

    componentDidMount() {
        let selectedChordSet = this.props.chordSets[0];
        this.setState({
            selectedChordSet:selectedChordSet
        })
        this.matchChordsToPatterns(selectedChordSet.patterns);
        
    }

    componentWillUnmount() {
        this.removeKeyListener();
    }

    static getDerivedStateFromProps(props, state) {
        return null;
    }

    addKeyListener = () => {
        document.addEventListener('keydown', this.clickNextChord);
    }

    removeKeyListener = () => {
        document.removeEventListener('keydown', this.clickNextChord);
    }

    startGame = () => {
        // mix chords
        this.shuffleChords(this.state.activeChords)

        // start game
        this.setState({
            started: true
        });

        // start timer if autoAdvance is on
        if( this.state.autoAdvance ){
            this.resetTimer();
        }

        this.addKeyListener();
    }

    setActiveChords = ( chords ) => {
        this.setState( () => ({
          activeChords: chords,
        }));
        this.shuffleChords(chords);
    }

    resetTimer = () =>{
        // clear old timer
        clearInterval(this.state.advanceIntervalId);
        
        // start new timer
        var intervalId = setInterval(this.autoAdvance, this.state.advanceSpeed);
        // store intervalId in the state so it can be accessed later:
        this.setState({advanceIntervalId: intervalId});
    }

    shuffleChords = (activeChords) => {
        const chordMix = shuffleArray(activeChords);
        this.setState( () => ({
          chordMix: chordMix 
        }));
    }

    onSelectChordSet = (e) => {
        // console.log(e);
        let selected = e.target.value;
        let chordIndex = this.props.chordSets.findIndex((el) => el.id === selected );
        let matchingChords = [];
        if( chordIndex >= 0 ) {
            // console.log(chordIndex)
            let patterns = this.props.chordSets[chordIndex].patterns;
            if( patterns ){
                this.props.chords.forEach((chordGroup, i) => {
                    chordGroup.forEach((chord) => {
                        patterns.forEach((pattern) =>{
                            if( chord.match(pattern) ){
                                matchingChords.push( chord );
                            }
                        })
                    });
                })
            }
            this.setActiveChords( matchingChords );
        }
        // use pattern to find chord in this.props.chords[i][j]
        // ([a-g]{1}#?\s) - regular chord

        this.setState( () => ({
            selectedChordSet: this.props.chordSets[chordIndex]
        }));
    }

    onChangeAdvance = (e) => {
        const autoAdvance = e.target.value === "true" ? true : false;
        this.setState({
            autoAdvance: autoAdvance
        })
    }

    onChangeCount = (e) => {
        const newCount = parseInt(e.target.value);
        this.setState({
            cardMax: newCount
        })
    }

    matchChordsToPatterns(patterns) {
        // console.log("matchChordsToPatterns", patterns);
        let matchingChords = [];
        this.props.chords.forEach((chordGroup, i) => {
            chordGroup.forEach((chord) => {
                patterns.forEach((pattern) =>{
                    if( chord.match(pattern) ){
                        matchingChords.push( chord );
                    }
                })
            });
        })
        this.setActiveChords( matchingChords );
    }

    autoAdvance = () => {
        this.setNextChord(1);
    }

    onSpeedChange = (e) => {
        const value = e.target.value;
        this.setState({
            advanceSpeed: value
        })
    }

    clickNextChord( event ) {
        if( event.keyCode === 39 || event.keyCode === 32 ){
            // Next chord
            this.setNextChord(1);
        } else if (event.keyCode === 37 ){
            // Previous chord
            // this.setNextChord(-1);
        }
    }

    setNextChord = (dir) => {
        let nextChord = undefined;
        let cardCount = this.state.cardCount;
        if( dir > 0 ){
            cardCount++;
            nextChord = (this.state.chordIndex + 1) % this.state.chordMix.length;
        } else if( dir < 0 ){
            // nextChord = (this.state.chordIndex - 1 +  this.state.chordMix.length) % this.state.chordMix.length;
        }

        if( this.state.cardMax > 0 ){
            if( cardCount >= 0 && cardCount < this.state.cardMax ){
                // console.log("nextChord", nextChord);
                this.setState({
                    cardCount: cardCount,
                    chordIndex: nextChord
                })
                // reset timer
                this.resetTimer();
            } else if( cardCount >= this.state.cardMax){
                this.endRound()
            }
        } else {
            this.setState({
                cardCount: cardCount,
                chordIndex: nextChord
            })
        }
    }

    endRound = () => {
        clearInterval(this.state.advanceIntervalId);
        this.setState({
            chordIndex: 0,
            cardCount: 0,
            started: false,
            advanceIntervalId: undefined
        })
        this.removeKeyListener();
    }

    render(){
        return <div className={`row h-100`}>
            <div className={`col-sm-12 col-md-6 offset-sm-0 offset-md-3 text-center my-auto`}>
                {this.state.started
                 ? <CardCanvas 
                    className={`h-100`}
                    selectedChordSet={this.state.selectedChordSet}
                    prevChord={this.state.chordMix[this.state.chordIndex-1] || undefined}
                    chord={this.state.chordMix[this.state.chordIndex]} 
                    cardCount={this.state.cardCount}
                    chordMax={this.state.cardMax}
                    endRound={this.endRound}></CardCanvas>
                 : <StartMenu {...this.props} activeChords={this.state.activeChords}
                    autoAdvance={this.state.autoAdvance}
                    advanceSpeed={this.state.advanceSpeed}
                    onSpeedChange={this.onSpeedChange}
                    startGame={this.startGame}
                    onSelectChordSet={this.onSelectChordSet}
                    onChangeAdvance={this.onChangeAdvance}
                    cardMax={this.state.cardMax}
                    onChangeCount={this.onChangeCount}
                    ></StartMenu>
                    
                }
            </div>
            {/* <ChordMenu chords={this.props.chords} chordSets={this.props.chordSets} activeChords={this.state.activeChords} setActiveChords={this.setActiveChords} /> */}
        </div>
    }
}


export default FlashCards